<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <div class="search_center">
              <span>单据编号：</span>
              <el-input
                v-model="parameter.expenseNumber"
                @change="dataUpdate"
                class="ipt_width expense__width"
                placeholder="请输入单据编号"
                clearable
              >
              </el-input>
            </div>
            <div class="search_center">
              <span>申请时间：</span>
              <el-date-picker
                class="date_width"
                v-model="createdDate"
                :pickerOptions="pickerOptions"
                type="daterange"
                range-separator="至"
                start-placeholder="申请日期（始）"
                end-placeholder="申请日期（末）"
              >
              </el-date-picker>
            </div>
            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <div class="fr">
          <el-button type="primary" @click="drawer = true">报销流程</el-button>
          <el-button type="warning" icon="el-icon-plus" @click="handleAdd">新增</el-button>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-tabs v-model="parameter.applyType" class="fullScreenMainHeader">
            <el-tab-pane name="UNDETERMINED">
              <span slot="label">未提交 {{ `(${this.statistics.undetermind})` }}</span>
            </el-tab-pane>
            <el-tab-pane name="APPROVAL">
              <span slot="label">审批中 {{ `(${this.statistics.approval})` }}</span>
            </el-tab-pane>
            <el-tab-pane name="APPROVAL_REJECT">
              <span slot="label">不通过 {{ `(${this.statistics.approvalReject})` }}</span>
            </el-tab-pane>
            <el-tab-pane name="NOT_PAYED">
              <span slot="label">已审核 {{ `(${this.statistics.notPayed})` }}</span>
            </el-tab-pane>
            <el-tab-pane name="APPROVAL_PASS">
              <span slot="label">已支付 {{ `(${this.statistics.approvalPass})` }}</span>
            </el-tab-pane>
          </el-tabs>
          <el-table
            v-loading="loading"
            ref="multipleTable"
            tooltip-effect="dark"
            height="string"
            border
            :data="tableData"
          >
            <el-table-column type="index" label="序号" align="center" width="50"></el-table-column>
            <el-table-column
              prop="expenseNumber"
              label="单据编号"
              align="center"
              width="160"
            ></el-table-column>
            <el-table-column
              prop="companyType"
              min-width="130"
              sortable
              label="费用所属单位"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.companyType | dict(companyTypeList) }}
              </template>
            </el-table-column>
            <el-table-column prop="sumCost" width="110" sortable label="报销金额" align="center">
              <template slot-scope="scope">
                {{ scope.row.sumCost | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="projectBudget"
              width="110"
              sortable
              label="填报时间"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              sortable
              width="180"
              prop="expenseMode"
              label="报销方式"
            >
              <template slot-scope="scope">
                {{ scope.row.expenseMode | dict(expenseModeList) }}
              </template></el-table-column
            >
            <el-table-column
              align="center"
              sortable
              width="180"
              prop="applyExpenseType"
              label="付款方式"
            >
              <template slot-scope="scope">
                {{ scope.row.applyExpenseType | dict(applyExpenseType) }}
              </template></el-table-column
            >
            <el-table-column align="center" width="150" sortable prop="statusName" label="流程进度">
              <template slot-scope="scope">
                {{ scope.row.statusName }}
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="120">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click.stop="handleDetails(scope.row)"
                  v-if="scope.row.status != 0"
                  class="text_Details_Bgc"
                  >详情</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click.stop="handleEdit(scope.row)"
                  v-if="scope.row.status == 0 || scope.row.status == 5"
                  class="text_Edit_Bgc"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click.stop="handleDelete(scope.row)"
                  v-if="
                    scope.row.status == 0 &&
                    scope.row.expenseMode != 'REGISTRATION_EXPENDITURE_DOCUMENT'
                  "
                  class="text_Remove_Bgc"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog width="75%" title="公共费用报销流程图" :visible.sync="drawer">
      <div style="padding: 5px">
        <img src="@/assets/公共费用报销.png" alt="公共费用报销流程图" width="100%" />
      </div>
    </el-dialog>
    <Edit
      v-if="editShow"
      :isGrant="true"
      :isShow.sync="editShow"
      :getTitle="sonTitle"
      :options="form"
    ></Edit>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Edit: () => import('../edit/CostEdit.vue'),
  },
  data() {
    return {
      loading: false, // 表格 加载
      parameter: {
        pageNow: 1,
        pageSize: 50,
        applyType: 'UNDETERMINED',
        expenseNumber: null, //申请编号
        startDate: null, //查询开始时间
        endDate: null, //查询结束时间
        total: 0, // 表格 -- 数据 总数量
      },
      pageSize: 0,
      tableData: [], // 表格数据
      editShow: false, // "编辑 -- 组件" 隐藏 或 显示
      companyTypeList: [], //公司所属单位列表
      createdDate: [], //查询内容申请时间
      drawer: false, //流程图显示隐藏
      statistics: {
        undetermind: 0,
        approval: 0,
        approvalReject: 0,
        notPayed: 0,
        approvalPass: 0,
      },
      expenseModeList: [],
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },
      applyExpenseType: [], //付款方式字典
    }
  },
  provide() {
    return {
      re: this.closeSubgroup,
    }
  },
  async created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    // 获取用户信息
    await this.getCompanyList()
    await this.getTableData()
    this.$api.dict.listSysDictData('EXPENSE_MODE', true).then(res => {
      this.expenseModeList = res.data
    })

    this.$api.dict
      .listSysDictData('APPLY_EXPENSE_TYPE', true)
      .then(res => {
        this.applyExpenseType = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  watch: {
    'parameter.applyType': {
      // immediate:true,//初始化立即执行
      // deep: true,//对象深度监测
      handler: function (newVal, oldVal) {
        this.parameter.pageNow = 1
        this.getTableData()
      },
    },
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getTableData()
        }
      },
    },
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },
  },
  methods: {
    getApplyStatistics() {
      this.$api.publicSpending
        .getApplyStatistics()
        .then(res => {
          if (res.data) {
            res.data.forEach(v => {
              if (v.applyType == 'UNDETERMINED') {
                this.statistics.undetermind = v.sumQuantity
              } else if (v.applyType == 'APPROVAL') {
                this.statistics.approval = v.sumQuantity
              } else if (v.applyType == 'APPROVAL_REJECT') {
                this.statistics.approvalReject = v.sumQuantity
              } else if (v.applyType == 'NOT_PAYED') {
                this.statistics.notPayed = v.sumQuantity
              } else if (v.applyType == 'APPROVAL_PASS') {
                this.statistics.approvalPass = v.sumQuantity
              }
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getCompanyList() {
      this.$api.dict
        .listSysDictData('COMPANY_TYPE')
        .then(res => {
          this.companyTypeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },

    //全局分页保存
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }

      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getTableData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getTableData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getTableData()
    },

    /** 加载数据 **/
    getTableData() {
      if (this.createdDate && this.createdDate != []) {
        this.parameter.startDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      } else if (this.createdDate == null) {
        this.parameter.startDate = ''
        this.parameter.endDate = ''
      }
      this.loading = true
      this.$api.publicSpending
        .getApplyForList(this.parameter)
        .then(res => {
          this.loading = false
          let result = res.data ? (res.data.records ? res.data.records : []) : []
          this.tableData = result
          this.parameter.total = res.data ? (res.data.total ? res.data.total : 0) : 0
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })

      this.getApplyStatistics()
    },

    /**  点击 "重置" 按钮 **/
    handleReset() {
      this.parameter.startDate = null
      this.parameter.endDate = null
      this.parameter.expenseNumber = null
      this.parameter.pageNow = 1
      this.createdDate = []
      this.getTableData()
    },

    /** 点击 "新增" 按钮 **/
    handleAdd() {
      this.sonTitle = '新增报销单'
      this.form = {}
      //* detailType用来标识详情的类型，0代表填报详情，1代表审核详情
      this.form.detailType = 0
      this.form.export = 0
      this.form.list_s = 0
      this.editShow = true
    },

    /**
     * 点击 "表格 -- 详情" 操作
     * @param item      当前数据
     */
    handleDetails(item) {
      this.sonTitle = '查看报销单详情'
      this.form = item
      this.form.detailType = 0
      if (item.status == 100) {
        // 点击已完成审核的详情
        this.form.list_s = 3
      } else {
        this.form.list_s = 2
      }
      if (this.parameter.applyType == 'NOT_PAYED') {
        this.form.export = 1
      } else {
        this.form.export = 0
      }
      this.editShow = true
    },

    handleEdit(item) {
      this.sonTitle = '编辑报销单'
      this.form = item
      this.form.detailType = 0
      this.form.export = 0
      this.form.list_s = 1
      this.editShow = true
    },

    handleDelete(item) {
      this.$confirm('请确认是否删除当前报销单？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.publicSpending.deleteExpense({ id: item.id }).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
            this.getTableData()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },

    /**
     * 点击状态
     * @param val     当前值
     */
    handleStatus(val) {
      this.parameter.applyType = val
      this.getTableData()
    },

    /**
     * 点击 "表格 -- 分页" 操作
     * @param page      当前页
     */
    handlerPage(page) {
      this.parameter.pageNow = page
      this.getTableData()
    },

    /** 关闭 "编辑" 组件 **/
    closeSubgroup() {
      this.form = {}
      this.getTableData()
      this.editShow = false
    },

    edit_status(item) {
      if (item.status == '0' || item.status == '5') {
        return true
      } else {
        return false
      }
    },

    dataUpdate() {
      this.$forceUpdate()
    },
  },

  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.oneline {
  display: flex;
  justify-content: space-between;
}
.search_center {
  display: flex;
  align-items: center;
}
</style>
